var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.colorSnak},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Cerrar ")])],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"grey lighten-3","dark":""}},[_c('v-card-text',[_vm._v(" Procesando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"grey darken-1"}})],1)],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" Lista de candidatos "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.candidatos,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.bloqueado != null)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.debloquearCandidato(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-open-variant ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Debloquear")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.verTestCandidato(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-greater-than ")])]}}],null,true)},[_c('span',[_vm._v("Historial test")])])]}},{key:"item.report",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.ultimo_test != null)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary","disabled":_vm.dialog,"loading":_vm.dialog},on:{"click":function($event){_vm.dialog = true; _vm.descargarPdf(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-down-thick ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Descargar último informe")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }