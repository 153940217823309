<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="grey lighten-3"
        dark
      >
        <v-card-text>
          Procesando...
          <v-progress-linear
            indeterminate
            color="grey darken-1"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        Lista de candidatos
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="candidatos"
        :search="search"
      >
        <template
          v-slot:item.actions="{ item }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.bloqueado != null"
                small
                class="mr-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="debloquearCandidato(item)"
              >
                mdi-lock-open-variant
              </v-icon>
            </template>
            <span>Debloquear</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="verTestCandidato(item)"
              >
                mdi-greater-than
              </v-icon>
            </template>
            <span>Historial test</span>
          </v-tooltip>
        </template>
        <template
          v-slot:item.report="{ item }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.ultimo_test != null"
                small
                class="mr-2"
                color="primary"
                :disabled="dialog"
                :loading="dialog"
                v-bind="attrs"
                v-on="on"
                @click="dialog = true; descargarPdf(item)"
              >
                mdi-arrow-down-thick
              </v-icon>
            </template>
            <span>Descargar último informe</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import UsuariosApi from '@/services/api/Usuarios'
  import TestApi from '@/services/api/Test'

  export default {
    data () {
      return {
        colorSnak: 'info',
        snackbar: false,
        text: '',
        timeout: 3000,
        dialog: false,
        search: '',
        candidatos: [],
        headers: [
          {
            text: 'Nombre',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Email',
            sortable: true,
            value: 'email',
          },
          {
            text: 'Acciones',
            align: 'center',
            sortable: false,
            value: 'actions',
          },
          {
            text: 'Informe',
            align: 'center',
            sortable: false,
            value: 'report',
          },
        ],
      }
    },
    created () {
      UsuariosApi.getAllCandidatos()
        .then((candidatos) => {
          this.candidatos = candidatos
        })
        .catch((error) => {
          console.log(error)
          this.text = 'No ha sido posible obtener los datos'
          this.colorSnak = 'error'
          this.snackbar = true
        })
    },
    methods: {
      verTestCandidato (item) {
        this.selectedItem = Object.assign({}, item)
        this.$router.push({ path: 'candidato/' + this.selectedItem.id })
      },
      debloquearCandidato (item) {
        this.selectedItem = Object.assign({}, item)
        UsuariosApi.desbloquearCandidato(this.selectedItem.id)
          .then((response) => {
            this.text = 'Usuario desbloqueado'
            this.colorSnak = 'success'
            this.snackbar = true
            setTimeout(() => { location.reload() }, 2000)
          })
          .catch((error) => {
            console.log(error)
            this.text = 'No ha sido posible realizar la acción'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
      descargarPdf (item) {
        this.progressBar = true
        this.selectedItem = Object.assign({}, item)
        this.id = this.selectedItem.ultimo_test
        TestApi.getTestPdf(this.id)
          .then(response => {
            var blob = new Blob([response, { type: 'application/pdf' }])
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Test Personalidad.pdf'
            link.click()
            this.dialog = false
          })
          .catch(error => {
            console.log(error)
            this.text = 'No ha sido posible generar el documento'
            this.colorSnak = 'error'
            this.snackbar = true
            this.dialog = false
          })
      },
    },
  }
</script>
